import React from "react";
import AllRoutes from "./routes/allRoutes";



const App = () => {
  
  return (
    <React.Fragment>
     
        <AllRoutes />
    </React.Fragment>
  );
};

export default App;
